<script setup lang="ts"></script>
<template>
  <v-app>
    <main-navigation />

    <v-main style="--v-layout-top: calc(var(--logo-height-l) + var(--spacer))">
      <slot />
    </v-main>

    <hw-footer />
  </v-app>
</template>
